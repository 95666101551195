import React, { useEffect } from 'react';
import { Buttons, DropOptionPicker, Form, LoadingIndicator, OccupyFreeSpace, SaveButton, SearchField, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { usePartiesPolls } from './usePartiesPolls';
import { PartiesPollsConfigEditForm } from './PartiesPollsConfigEditForm';
import { PartiesPollsList } from './PartiesPollsList';
import { FieldSettingsPopupButton } from '../../toolympus/components/schemed';
import { Button, IconButton, Typography } from '@mui/material';
import { GetAppOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { PluggableDocumentationButton } from '../../toolympus/components/Documentation';

interface Props {
  
}

export const PartiesPollsPage = (props: Props) => {
  const tabs = useTabsState([
    ["polls", "Опросы"],
    ["polls_unlinked", "не привязанные к делам"],
    ["config", "Конфигурация"],
  ], "polls", "tab", "__polls_tab");

  const { config, polls } = usePartiesPolls({ unlinkedPollsOnly: tabs.current === "polls_unlinked" });
  useSaveable(config);


  useEffect(() => {
    const search = new URLSearchParams(window.location.search).get("search");
    if(search) {
      polls.filter.setFilter(search);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form title="Опросы сторон"
        headerItems={
          <Buttons fullWidth alignItems="flex-end">
            <Link to="/parties-polls/prep">
              <Button size="small" color="primary">подготовка</Button>
            </Link>
            <Typography variant="caption" color="textSecondary">
              Всего записей: {polls.count.data.total_records}
            </Typography>
            <OccupyFreeSpace />
            {(config.isLoading || polls.isLoading) && <LoadingIndicator sizeVariant="m" />}
            {tabs.current === "config" && config.hasChanges && <SaveButton action={() => config.save()} />}
            {tabs.current === "polls" &&
              <SearchField
                filter={polls.filter.filter}
                setFilter={polls.filter.setFilter}
                autoFocus
                noButton
                small
                />}
            {tabs.current === "polls" && 
              <DropOptionPicker
                options={[
                  ["все", "all"],
                  ["заполненные", "filled"],
                  ["незаполненные", "unfilled"],
                ]}
                selected={polls.view}
                setSelected={polls.setView}
                />
              }
            {tabs.current === "polls" && <IconButton size="small" onClick={() => polls.exportXlsx()}><GetAppOutlined /></IconButton>}
            {tabs.current === "polls" && <FieldSettingsPopupButton fieldsSettings={polls.fields} />}
            <PluggableDocumentationButton documentationKey="parties_polls" />
          </Buttons>}>

        <TabsHeader state={tabs} noMargin />

        <TabPanel state={tabs.forTab("config")}>
          <PartiesPollsConfigEditForm data={config} />
        </TabPanel>

        <TabPanel state={tabs.forTab("polls")}>
          <PartiesPollsList data={polls} />
        </TabPanel>

        <TabPanel state={tabs.forTab("polls_unlinked")}>
          <PartiesPollsList data={polls} />
        </TabPanel>
        
    </Form>
);
}
