import React, { ReactNode, useState } from 'react';
import { Button, ButtonProps, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

interface DropOptionPickerProps<OptionType> {
    selected: OptionType;
    setSelected: (o: OptionType) => void;
    options: [ReactNode, OptionType][];
    buttonProps?: Omit<ButtonProps, "onClick">;
    keepMenu?: boolean;
}

export const DropOptionPicker = <OptionType, >(props: DropOptionPickerProps<OptionType>) => {
  const { options, selected, setSelected } = props;
  const [menuAnchor,setMenuAnchor] = useState<any>(null);

  const selectedOption = options.find(o => o[1] === selected);

  return <>
    <Button
      size="small"
      color="primary"
      endIcon={<MoreVert />}
      {...props.buttonProps || {}}
      onClick={e => setMenuAnchor(e.target)}>
      {selectedOption
        ? selectedOption[0]
        : "..."}
    </Button>
    {(!!menuAnchor || props.keepMenu) &&
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)} keepMounted={props.keepMenu}>
        {options
          .map(([label, option]) => (
            <MenuItem
              key={option as any}
              color={selected === option ? "primary" : undefined}
              onClick={() => { if(selected !== option) { setSelected(option); } setMenuAnchor(null); }}>
                {label}
            </MenuItem>
          ))}
      </Menu>}
  </>
}
