import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import { ChatBubbleOutline, Message, Send } from '@mui/icons-material';
import { CommentsData, Comment } from './useComments';
import { FormControl, useFormats } from '../schemed';
import { FieldType } from '../../hooks/useSchema';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { utc } from '../timezone';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { FormattedMessage } from 'react-intl';
import { DeleteButton } from '../primitives/DeleteButton';
import isHotkey from 'is-hotkey';
import { useCommentsConfiguration } from './CommentsConfiguration';

interface Props {
    data: CommentsData;
}

interface DialogProps extends Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
}


const CommentsList = styled.div`
    display: flex;
    flex-flow: column;
    gap: 1rem;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row;
    padding: 8px 16px;
    align-items: flex-end;

    &>:first-child {
        flex-grow: 1;
    }
`;

const Username = styled(Typography)`
    font-style: italic;
`;
Username.defaultProps = { variant: "caption" };
const Time = styled(Typography)`
    font-style: italic;
    color: ${props => props.theme.palette.grey[400]};
`;
Time.defaultProps = { variant: "caption" };

const CommentWrapper = styled.div`
    padding: 0.5rem 0;
    border-bottom: 1px solid ${props => props.theme.palette.grey[200]};

    &:first-child {
        border-top: 1px solid ${props => props.theme.palette.grey[200]};
    }

    & > div:first-child {
        width: 100%;
        display: flex;
        flex-flow: row;
    }
`;


interface CommentProps {
    comment: Comment;
    canRemove: boolean;
    remove: (c: Comment) => Promise<any>;
}

const CommentItem = ({ comment, canRemove, remove }: CommentProps) => {
    const formats = useFormats();
    return (<CommentWrapper>
        <div>
            <Username>{comment.user_name}</Username>
            <OccupyFreeSpace />
            {canRemove &&
                <DeleteButton title={<FormattedMessage id="comments.deleteConfirmation" />}
                    size="tiny"
                    preventGoBack
                    remove={() => remove(comment)} />}
            <Tooltip title={formats.formatDate(utc.toLocal(comment.created_at))}>
                <Time>{formats.formatDatetimeShort(utc.toLocal(comment.created_at))}</Time>
            </Tooltip>
        </div>
        {comment.comment.split('\n').map(line => <Typography variant="body1">{line}</Typography>)}
    </CommentWrapper>);
}


interface CommentsDisplayProps {
  comments: Comment[];
  remove?: (c: Comment) => Promise<any>;
  isRemoveAllowed?: (c: Comment) => boolean;
  onClick?: () => void;
}

const reCommentPromise = (c: Comment) => Promise.resolve(c);
const constantlyFalse = () => false;

export const CommentsDisplay = (props: CommentsDisplayProps) => {
  const { comments } = props;

  const isRemoveAllowed = props.isRemoveAllowed || constantlyFalse;
  const remove = props.remove || reCommentPromise;

  return (
    <CommentsList className="comments-list" onClick={props.onClick}>
      {comments.map(c => <CommentItem key={c._id} comment={c} canRemove={isRemoveAllowed(c)} remove={remove} />)}
    </CommentsList>)
}

export const CommentsDialog = (props: DialogProps) => {
    const { comments, isLoading, newComment, setNewComment, addNew, isRemoveAllowed, remove } = props.data;
    const { isOpen, setIsOpen } = props;

    const scrollRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            if(scrollRef.current != null) {
                // @ts-ignore 
                scrollRef.current.scrollIntoView(({ behavior: "smooth" }));   
            }
        }, 200);
    }, [isOpen, comments]);

    const addComment = () => {
        if(!isLoading && newComment && newComment.trim().length > 0) {
            addNew();
        }
    }
    const onTextKeyDown = (e: any) => {
        if(isHotkey("mod+Enter", e)) {
            addNew();
        }
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={() => setIsOpen(false)}>
            <DialogTitle>
                <FormattedMessage id="comments.title" />
            </DialogTitle>
            <DialogContent>
                <CommentsList>
                    {comments.map(c => <CommentItem comment={c} canRemove={isRemoveAllowed(c)} remove={remove} />)}
                </CommentsList>
                <div ref={scrollRef} />                
            </DialogContent>
            <DialogActions>
                <Footer>
                    <FormControl
                        field="comment"
                        schema={{ label_id: "comments.newComment", type: FieldType.textlong }}
                        row={{ comment: newComment }}
                        onChange={(_, changes) => setNewComment(changes.comment)}
                        extraProps={{ autoRows: true, controlProps: { onKeyDown: onTextKeyDown, autoFocus: true } }}
                        />
                    {isLoading ?
                        <LoadingIndicator /> :
                        <Button
                            color="primary"
                            endIcon={<Send />}
                            style={{ alignSelf: 'flex-end'}}
                            onClick={() => addComment()}>
                        </Button>}
                </Footer>
            </DialogActions>
        </Dialog>
    );
}

export const CommentsForm = (props: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const config = useCommentsConfiguration();
    const AbsentIcon = config.commentAbsentIcon || <ChatBubbleOutline />
    const PresentIcon = config.commentPresentIcon || <Message />

    return (<>
        <IconButton onClick={() => setIsOpen(true)}>
            {props.data.comments.length > 0 ? PresentIcon : AbsentIcon}
        </IconButton>
        <CommentsDialog data={props.data} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>);
}
