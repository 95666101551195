import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#5B99D2',
        },
        secondary: {
            main: '#AF252A',
        }
    },

    typography: {
      h4: {
        fontSize: "1.5rem",
      },
    },

    components: {
      ...BaseMuiThemeComponentsSettings,

      MuiInputBase: {
        styleOverrides: {
          root: {
            "&:before": {
              borderBottomColor: "#00000030 !important",
            }
          },
        },
      },

      MuiLink: {
        styleOverrides: {
          root: {
            textUnderlineOffset: 3,
            textDecorationStyle: "dotted",
          },
        },
      },
    },
    
});
