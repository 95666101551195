import React from 'react';
import styled from '@emotion/styled';
import { PowerDocPageConfig, PowerEditor, PowerEditorBaseProps, PowerEditorConfig, PowerEditorStockConfig } from '../../toolympus/components/PowerDoc';
import { FormBody, FormGrid, FormHeader, FormTitle, FormWrapper, LoadingIndicator, OccupyFreeSpace, SaveButton, useSaveable } from '../../toolympus/components/primitives';
import { useParams } from 'react-router-dom';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { Autocomplete, Button, Link, TextField, Typography } from '@mui/material';
import { BackgroundGray } from '../Personalization/BackgroundOptions';
import { useEditDocumentTemplate } from './useEditDocumentTemplate';
import { DocumentTemplateParamsConfig } from './DocumentTemplateParamsConfig';

interface Props extends PowerDocPageConfig {
  editorConfig?: PowerEditorConfig & PowerEditorStockConfig;
  editor?: React.ComponentType<PowerEditorBaseProps>;
  viewMode?: boolean;
  backLink?: string;
}

const AttributesPanel = styled(FormGrid)`
  ${BackgroundGray.css};
  padding: 0 12px 12px;
  border-radius: 4px;
  height: 100%;
`;

export const DocumentTemplateEditPage = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const data = useEditDocumentTemplate(id);

  const Editor = props.editor;
  const { viewMode } = props;

  useSaveable(data);

  return (
    <FormWrapper>
        <FormHeader style={{ minHeight: 34 }}>
            <FormTitle><Link href={props.backLink}>Шаблон</Link></FormTitle>
            <OccupyFreeSpace />
            {data.isLoading && <LoadingIndicator />}
            {data.hasChanges && <SaveButton action={() => data.save()} />}
            <Button size="small" color="primary">
              сгенерировать превью
            </Button>
        </FormHeader>
        
        <FormBody>
          <FormGrid columns="2fr 1fr" noMargin>
            <FormControlsForFields
              fields={[
                ["title", { label: " "}]
              ]}
              data={data.data}
              onChange={(o,c) => data.update(c)}
              schema={{}}
              />

            <Autocomplete
              freeSolo
              multiple
              value={data.data.tags || []}
              onChange={(e, newValue) => {
                data.update({ tags: newValue as any });
              }}
              onInputChange={(e, newValue) => {
                if(e) {
                  // data.update({ source_title: newValue || "" });
              }}}
              autoHighlight
              fullWidth
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  margin="none"
                  label="Теги"
                  />)}
              options={data.tags.data}
              />
          </FormGrid>

          <FormGrid columns="2fr 1fr">
            <FormGrid columns="1fr" noMargin>
              <Typography variant="caption" color="textSecondary">Текст документа</Typography>
              {data.isLoading && !data.data.content
                  ? <LoadingIndicator />
                  : (Editor
                      ? <Editor
                          content={data.data.content}
                          update={c => data.update({ content: c })}
                          viewMode={viewMode}
                          />
                      : <PowerEditor
                          content={data.data.content}
                          update={c => data.update({ content: c })}
                          {...(props.editorConfig || {})}
                          viewMode={viewMode}
                          />)}
            </FormGrid>

            <AttributesPanel noMargin columns="1fr">
              

              <DocumentTemplateParamsConfig data={data.params} />
            </AttributesPanel>
            
          </FormGrid>
        </FormBody>

        {/* {data.hasChanges && <LowerSaveButtonWrapper><SaveButton action={() => data.save()} /></LowerSaveButtonWrapper>} */}
    </FormWrapper>
  );
}
