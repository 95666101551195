import { useEffect, useState } from "react";
import { apiFetch } from "../../../toolympus/api/core";
import { useDialogState } from "../../../toolympus/components/primitives";
import { Arbitrator } from "../../../typings/Arbitrators";
import { useSnackbar } from "notistack";

export const getRandomArbitratorIdByFilters = (filtersStringified: string): Promise<Arbitrator | { _id?: string }> => {
  return apiFetch<{ _id?: string }>(`/api/arbitrator/random-candidate?${filtersStringified}`, "get");
}

export const usePickRandomArbitrator = (filtersStringified: string) => {
  const dialog = useDialogState();
  const [selected,setSelected] = useState<Arbitrator[]>([]);
  const [isLoading,setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const getOne = (explicitExclude?: string[]) => {
    const selectedIds = explicitExclude || selected.map(a => a._id);
    const excludeParam = selectedIds.length ? `exclude-ids=${selectedIds.join(",")}` : "";
    const fullParams = [filtersStringified, excludeParam].filter(x => !!x).join("&")
    return getRandomArbitratorIdByFilters(fullParams);
  }

  const addOne = async () => {
    setIsLoading(true);
    try {
      const picked = await getOne();
      if(picked._id) {
        selected.push(picked as Arbitrator);
      } else {
        enqueueSnackbar("Не удалось найти подходящего арбитра", { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const reroll = async (idx: number) => {
    setIsLoading(true);
    try {
      const picked = await getOne();
      if(picked._id) {
        setSelected(x => x.map((a,i) => i === idx ? picked as Arbitrator : a));
      } else {
        enqueueSnackbar("Не удалось найти подходящего арбитра", { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const rerollAll = async () => {
    setIsLoading(true);
    let exclude = [...selected.map(a => a._id)];

    let newSelected: Arbitrator[] = [];

    for (let i = 0; i < selected.length; i++) {
      try {
        const picked = await getOne(exclude);
        if(picked._id) {
          newSelected.push(picked as Arbitrator);
          exclude.push(picked._id);
        } else {
          enqueueSnackbar("Не удалось найти подходящего арбитра", { variant: "error" });
          break;
        }
      } finally {
      }
    }
    setSelected(newSelected);
    setIsLoading(false);
  }
  
  const remove = (idx: number) => {
    setSelected(x => x.filter((a,i) => i !== idx));
  }

  useEffect(() => {
    if(!dialog.isOpen) {
      setSelected([]);
    }
  }, [dialog.isOpen]);

  return {
    dialog,
    selected,
    isLoading,
    addOne,

    remove,
    reroll,
    rerollAll,
  }

}


export type PickRandomArbitratorData = ReturnType<typeof usePickRandomArbitrator>;
