import { Button, IconButton } from '@mui/material';
import { Add, DeleteOutlined, GetAppOutlined } from '@mui/icons-material';
import React from 'react';
import { ActionRow, LoadingIndicator, OccupyFreeSpace, PseudoLink } from '../../../toolympus/components/primitives';
import { TableForFields } from '../../../toolympus/components/schemed';
import { FieldType, Schema } from '../../../toolympus/hooks/useSchema';
import { CasePowerDocsData } from '../caseData/useCasePowerDocs';
import { CaseDocumentDialog } from './CaseDocumentDialog';
import { GenerateDocumentDialog } from '../../PowerEditor';

interface Props {
    data: CasePowerDocsData;
}

const PowerDocSchema: Schema = {
    title: { label: "Название" },
    created_datetime: { label: "Создан", type: FieldType.datetime },
}

export const CaseDocuments = (props: Props) => {
    const {
        documents,
        removeDocument,
        generateDocument,
        isGenerating,
        editDocument,
        generator,
    } = props.data;


    return (
        <>
            <ActionRow>
                <OccupyFreeSpace />
                <Button
                    color="primary"
                    onClick={() => generator.start()}
                    startIcon={<Add />}
                    >
                    создать
                </Button>
            </ActionRow>
            <TableForFields
                fields={[
                    ["title"],
                    ["created_datetime", { utcToLocal: true }],
                ]}
                schema={PowerDocSchema}
                data={documents.data}
                rowButtons={row => <>
                    <IconButton size="small" onClick={() => removeDocument.run(row)}>
                        <DeleteOutlined />
                    </IconButton>
                    <IconButton size="small" onClick={() => generateDocument(row._id)}>
                        {isGenerating ? <LoadingIndicator sizeVariant="s" /> : <GetAppOutlined />}
                    </IconButton>
                </>}
                fieldElement={f => {
                    if(f === "title") {
                        return (row, schema, original) => <PseudoLink onClick={() => editDocument.open(row._id)}>{original}</PseudoLink>
                    }
                }}
                />
            

            <CaseDocumentDialog
              isOpen={editDocument.isOpen}
              close={editDocument.close}
              data={editDocument}
              isGenerating={isGenerating}
              generateDocument={generateDocument}
              />

            <GenerateDocumentDialog
              data={generator}
              />
        </>
    );
}
