import { useCallback, useEffect, useState } from "react";
import { apiFetch, FetchTypes } from "../../../toolympus/api/core";
import { LoadedData } from "../../../toolympus/hooks/useLoadedData";
import { useSendData } from "../../../toolympus/hooks/useSendData";
import { Arbitrator, ArbitratorCreate } from "../../../typings/Arbitrators";
import { SaveableData } from "../../Cases/caseData";
import { Case } from '../../../typings/Cases';
import { useHistory } from "react-router-dom";
import { FieldSorting } from "../../../toolympus/hooks/useFieldSorting";
import { FieldFilters } from "../../../toolympus/components/schemed/Filtering";
import { useSchema } from "../../../toolympus/hooks/useSchema";
import { useValidationErrors } from "../../../toolympus/components/schemed";
import { useArbitratorDocuments, SimpleDocumentListData } from "./useArbitratorDocuments";
import { ArbitratorSuggestionsData, useArbArbitratorSuggestions } from "../../Presidium/Questions/useArbitratorSuggestions";
import { useQuery } from "../../../toolympus/hooks/useQueryParamsState";
import { useArbitratorsSearch } from "./useArbitratorsSearch";
import { SmartListData, useSmartList } from "../../../toolympus/hooks/useSmartList";
import { useThrottledDoubleState } from "../../../toolympus/components/primitives";
import { PickRandomArbitratorData, usePickRandomArbitrator } from "../ArbitratorsSelection/usePickRandomArbitrator";

export interface ArbitratorListData extends LoadedData<Arbitrator[]> {
    filter: string;
    setFilter: (v: string) => void;
    applyFilter: () => void;
    sorting: FieldSorting;
    filtering: FieldFilters;

    includeDontAssign: boolean;
    setIncludeDontAssign: (v: boolean) => void;
    includeNoList: boolean;
    setIncludeNoList: (v: boolean) => void;
    newArbitrator: NewArbitratorData;
    limitTrigger: SmartListData<Arbitrator>["limitTrigger"];
    count: SmartListData<Arbitrator>["count"];
    pickRandom: PickRandomArbitratorData;
}

export const useArbitratorList = (): ArbitratorListData => {
    const history = useHistory();
    const query = useQuery();
    const { arbitrator: schema } = useSchema();

    const [filter, setFilter, appliedFilter] = useThrottledDoubleState<string>(query.get('filter') || "", 500);
    
    const [includeDontAssign, setIncludeDontAssign] = useState<boolean>(true);
    const [includeNoList, setIncludeNoList] = useState<boolean>(false);
    
    const baseUrl = '/api/arbitrator';
    
    const data = useSmartList<Arbitrator>(baseUrl, {
      newRecordDefault: { },
      schema,
      sorting: { },
      lsKeysPrefix: "_raca_arbitrators",
      noView: true,
      extraParams: {
        filter: appliedFilter && appliedFilter.length ? appliedFilter : undefined,
        dont_assign: includeDontAssign ? "include" : undefined,
        no_list: includeNoList ? undefined : "exclude",
      },
      fieldFiltersSettingsKey: "filters_arbitrators",
    });

    const newArbitrator = useNewArbitrator(a => history.push(`/arbitrator/${a._id}`));

    const pickRandom = usePickRandomArbitrator(data.queryNoLimit);

    useEffect(() => {
        history.replace(`/arbitrators/?filter=${appliedFilter || ''}`);
    }, [appliedFilter, history]);

    return {
        ...data,
        filter,
        setFilter,
        applyFilter: () => {},

        includeDontAssign,
        setIncludeDontAssign,
        includeNoList,
        setIncludeNoList,
        newArbitrator,

        pickRandom,
    }
} 

export interface ArbitratorData extends SaveableData<Arbitrator> {
    cases: Case[];
    documents: SimpleDocumentListData;
    remove: () => Promise<{}>;

    suggestions: ArbitratorSuggestionsData;
};

export const useArbitrator = (arbitratorId: string): ArbitratorData => {
    const [arbitrator, setArbitrator] = useState<Arbitrator>({ _id: arbitratorId} as Arbitrator);
    const [changes, setChanges] = useState<Partial<Arbitrator>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cases, setCases] = useState<Case[]>([]);
    const errors = useValidationErrors();
    const documents = useArbitratorDocuments(arbitratorId);
    const suggestions = useArbArbitratorSuggestions(arbitratorId);

    const load = useCallback(() => {
        setIsLoading(true);

        apiFetch<Arbitrator>(`/api/arbitrator/${arbitratorId}`)
            .then(setArbitrator)
            .finally(() => setIsLoading(false))

        apiFetch<Case[]>(`/api/arbitrator/${arbitratorId}/case`)
            .then(setCases)
            .finally(() => setIsLoading(false))
    }, [arbitratorId]);

    useEffect(() => {
        load();
    }, [load]);

    const update = (changes: Partial<Arbitrator>) => {
        // @ts-ignore
        const moreChanges = Object.keys(changes).reduce((r,k) => changes[k] === arbitrator[k] ? r : { ...r, [k]: changes[k]}, {});
        setArbitrator(c => ({ ...c, ...changes }));
        setChanges(c => ({ ...c, ...moreChanges }));
    };

    const save = () => {
        setIsLoading(true);
        return apiFetch<Arbitrator>(`/api/arbitrator/${arbitratorId}`, FetchTypes.PUT, changes)
            .then(setArbitrator)
            .then(() => setChanges({}))
            .then(() => errors.clearErrors())
            .catch(e => { errors.handleErrors(e); throw e; })
            .finally(() => setIsLoading(false))
    };

    const remove = () => apiFetch<{}>(`/api/arbitrator/${arbitratorId}`, FetchTypes.DELETE);

    return {
        data: arbitrator,
        setData: (x) => {},
        isUpdated: Object.keys(changes).length !== 0,
        cases,
        isLoading,
        reload: load,
        update,
        save,
        remove,
        errors,
        documents,
        suggestions,
    };
}

export const useNewArbitrator = (onCreated: (a: Arbitrator) => void) => {
    const data = useSendData<ArbitratorCreate, Arbitrator>(
        '/api/arbitrator',
        { firstname: "", lastname: "", middlename: "" },
        { onSuccess: onCreated}
        );

    const duplicatesSearch = useArbitratorsSearch();

    useEffect(() => {
        duplicatesSearch.setSearchPhrase(`${data.data.lastname} ${data.data.firstname}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.data.lastname, data.data.firstname]);

    return {
        ...data,
        duplicatesSearch,
    }
}

export type NewArbitratorData = ReturnType<typeof useNewArbitrator>;
