import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { PickRandomArbitratorData } from './usePickRandomArbitrator';
import { Buttons, Dialog, EmptyState, LoadingIndicator, Tooltip, useCopyText } from '../../../toolympus/components/primitives';
import { Button, IconButton, Link, Paper, Typography } from '@mui/material';
import { TableForFields } from '../../../toolympus/components/schemed';
import { Schema } from '../../../toolympus/hooks/useSchema';
import { Add, Close, FileCopyOutlined, RefreshOutlined } from '@mui/icons-material';
import { SlotMachine } from './SlotMachine';

const DialogPaper = styled(Paper)`
  position: relative;

  & .slot-machine {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    backdrop-filter: blur(2px);

    opacity: 0;
    z-index: 1;

    &.spinning, &.lingering {
      opacity: 1;
      z-index: unset;
    }
  }
`;

interface Props {
  data: PickRandomArbitratorData;
  stringifiedFilters: string;
  schema: Schema;
  comments?: (recordId: string) => ReactNode;
}

export const PickRandomArbitratorDialog = (props: Props) => {
  const { data } = props;

  const copyText = useCopyText();

  const copyNames = () => {
    const text = data.selected.map(a => [a.lastname, a.firstname, a.middlename].filter(x => !!x).join(" ")).join(", ");
    copyText(text);
  }

  return (
    <Dialog
      {...data.dialog}
      maxWidth="lg"
      fullWidth
      dialogTitle="Случайные арбитры"
      titleActions={<>
        {data.isLoading && <LoadingIndicator sizeVariant="font" />}
        {!!data.selected.length &&
          <Button size="small" startIcon={<FileCopyOutlined />} onClick={() => copyNames()}>скопировать имена</Button>}
      </>}
      PaperComponent={DialogPaper}
      >


      <Typography variant="caption" component="p" color="textSecondary">
        Арбитры подбираются по выбранным на списке фильтрам. Подобрать можно сколько угодно.
      </Typography>

      <TableForFields
        data={data.selected}
        fields={[
          ["fullname", { label: "Арбитр"}],
          ["gender"],
          ["dont_assign_ext"],
          ["astats_last_assigned_date"],
          ["astats_last_suggested_date"],
          ["astats_suggested_draft_nbr"],
          ["experts_list_site"],
          ["actions", { label: " "}],
        ]}
        schema={props.schema}
        emptyState={<EmptyState onClick={() => data.addOne()} text="подобрать случайного арбитра" />}
        fieldElement={f => {
          if(f === "fullname") {
            return (r) => (
              <Link style={{ whiteSpace: "nowrap" }} href={`/arbitrator/${r._id}`} target="_blank" rel="noreferrer noopener">
                {[r.lastname, r.firstname, r.middlename].filter(x => !!x).join(" ")}
              </Link>
            );
          }
          if(f === "actions") {
            return (r) => (
              <Buttons>
                {!!props.comments &&
                  props.comments(r._id)}
                <Tooltip text="убрать">
                  <IconButton size="small" color="secondary" onClick={() => data.remove(data.selected.indexOf(r))}><Close /></IconButton>
                </Tooltip>
                <Tooltip text="заменить">
                  <IconButton size="small" color="primary" onClick={() => data.reroll(data.selected.indexOf(r))}><RefreshOutlined /></IconButton>
                </Tooltip>
              </Buttons>
            )
          }
        }}
        />

      <Buttons style={{ justifyContent: "center", marginTop: "0.5rem" }}>
        <SlotMachine
          isSpinning={data.isLoading}
          spinRelease={500}
          lingerDuration={1000}>

        </SlotMachine>
      </Buttons>
      <Buttons style={{ justifyContent: "center", marginTop: "0.5rem" }}>
          <Button size="small" color="primary" startIcon={<Add />} onClick={() => data.addOne()}>{data.selected.length ? "ещё" : "подобрать"}</Button>
          {!!data.selected.length &&
            <Button size="small" startIcon={<RefreshOutlined />} onClick={() => data.rerollAll()}>заменить всех</Button>}
      </Buttons>
      
    </Dialog>
  );
}
