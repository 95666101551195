import { useItemActionWithConfirmation } from "../../../toolympus/api/useAction";
import { PowerDoc, RecordPowerDocsConfig, useRecordPowerDocsList } from "../../../toolympus/components/PowerDoc";
import { PowerDocApiPath } from "../../PowerEditor";
import { useGenerateDocument } from "../../PowerEditor/useGenerateDocument";
import { useSettings } from "../../SettingsPage";

const PowerDocCaseEntity = "case";


export const useCasePowerDocs = (caseId: string, cfg?: Partial<RecordPowerDocsConfig>) => {
    const { settings } = useSettings();

    const documents = useRecordPowerDocsList(PowerDocApiPath, PowerDocCaseEntity, caseId, {
      ...(cfg || {}),
      dotxTemplateUrl: settings.document_generator_dotx_url || undefined,
    });

    const removeDocument = useItemActionWithConfirmation<PowerDoc, {}>(
      item => {
        documents.removeDocument(item._id);
        return Promise.resolve({});
      }, {
        title: "Удалить документ",
      }
    );

    const generator = useGenerateDocument({
      ...(cfg || {}),
      templatesApiPath: "/api/powerdoc/doc",
    });

    return {
      ...documents,
      removeDocument,
      generator,
    }
}

export type CasePowerDocsData = ReturnType<typeof useCasePowerDocs>;
