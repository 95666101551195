import { useEffect, useMemo } from "react";
import { FieldSchema, FieldType, Schema, mergeSchema, useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useFields } from "../../toolympus/components/schemed";
import { PollConfig, PartyPoll, pollConfigToFields } from "./polls.types";
import { exportPollsToXlsx } from "./PartiesPollsExport";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { apiFetch } from "../../toolympus/api/core";
import { PollRequest } from "./usePartiesPollsPrep";
import { useSmartList } from "../../toolympus/hooks/useSmartList";


const ApiPath = "/api/party-poll";


export interface LinkPollToCase extends Pick<PollRequest, "case_id" | "side_kind" | "side_title"> {
  poll_id: string | undefined;
}

export interface PartiesPollListCfg {
  unlinkedPollsOnly?: boolean
}

export const usePartiesPollsList = (config: PollConfig, listCfg: PartiesPollListCfg) => {
  const { schema: schemaBase } = useSingleSchema(`${ApiPath}/poll/uiconfig`);

  const data = useSmartList<PartyPoll>(`${ApiPath}/poll`, {
    newRecordDefault: {},
    schema: schemaBase,
    // noLimit: true,
    lsKeysPrefix: "_raca_party_polls_sl",
    viewDefault: "all",
  })


  const [schemaFields, configFields, transformed] = useMemo(() => {
    const fields = pollConfigToFields(config);
    const schema = fields.reduce<Schema>((r,f) => { r[f.key] = f as FieldSchema; return r; }, {});
    
    const transformed = data.data.map(item => ({ ...item, ...(item.fields || {}) } as PartyPoll));

    return [schema, fields, transformed];

  }, [config, data.data]);

  const schema = useMemo(() => mergeSchema(
    schemaBase, {
      ...schemaFields,
      created_datetime: { type: FieldType.datetime, label: "Создан" },
      filled_datetime: { type: FieldType.datetime, label: "Заполнен" },
    }), [schemaBase, schemaFields]);

  const fields = useFields({
    defaultFields: [],
    schema,
    storageKey: "__raca_parties_polls_fields",
    extraSettings: {
      created_datetime: { utcToLocal: true },
      filled_datetime: { utcToLocal: true },
      casenbr: { label: "Номер дела" },
      email: { label: "Email" },
    }
  });

  useEffect(() => {
    const configSections = config?.config?.fields || [];
    if(fields.includedFields.length === 0 && configSections.length > 0) {
      const fieldsIncluded = [
        "casenbr",
        "email",
        "filled_datetime",
      ];

      configSections.forEach(section => {
        (section.fields || []).forEach(field => {
          const field_key = `${section.section}_${field.code}`;
          fieldsIncluded.push(field_key);
        })
      });

      fields.setFields(fieldsIncluded);
      fields.changeDefaultFields(fieldsIncluded);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.includedFields, config]);

  const rowToSearchableText = useMemo(() => {
    const firstSection = (config?.config?.fields || [])[0];
    if(!firstSection) {
      return (poll: PartyPoll) => `${poll._id}`;
    }

    const fieldsKeys = firstSection.fields.map(f => `${firstSection.section}_${f.code}`);

    return (poll: PartyPoll) => `${poll._id} ${poll.request_merge_group} ${fieldsKeys.map(fk => (poll.fields || {})[fk] || "").join(" ")}`;
  }, [config]);

  const filter = useTextFilter<PartyPoll>(rowToSearchableText)

  const records = filter.filterData(transformed);

  const reviewPoll = useEditItem2<PartyPoll>({
    getApiPath: () => "",
  });

  const linkToCase = useEditItem2<Partial<LinkPollToCase>>({
    save: (item, changes) => {
      const copy = { ...item };
      delete copy.case_id;
      delete copy.poll_id;
      return apiFetch<{}>(`/api/party-poll/poll/${item.poll_id}/link`, "post", { ...copy, cases: [item.case_id] })
        .then(() => { data.reload(); return item; });
    }
  })

  return {
    ...data,
    data: listCfg.unlinkedPollsOnly ? records.filter(r => !r.request_merge_group) : records,
    schema,
    config,
    configFields,
    fields,
    filter,
    exportXlsx: () => exportPollsToXlsx(config, records),
    reviewPoll,
    linkToCase,
  }
}

export type PartiesPollsListData = ReturnType<typeof usePartiesPollsList>;