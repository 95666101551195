import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { PowerDoc } from "../../toolympus/components/PowerDoc";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useDocumentTemplateParamsConfig } from "./useDocumentTemplateParamsConfig";

export interface DocTemplate extends PowerDoc {
  tags?: string[];
}

export const useEditDocumentTemplate = (id: string) => {
  const data = useCrudItem<DocTemplate>(`/api/powerdoc/doc/${id}`, {
    defaultValue: {} as DocTemplate,
  });

  const tags = useLoadedData<string[]>(`/api/powerdoc/tags`, []);


  const params = useDocumentTemplateParamsConfig({
    data: data.data.template_parameters,
    update: v => data.update({ template_parameters: v })
  });

  return {
    ...data,
    tags,
    params,
  }
}
