import { useState } from "react";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { DocTemplate } from "./useEditDocumentTemplate";
import { collectDocImages, DefaultExpressionProcessor, processPowerDocTemplate, RecordPowerDocsConfig } from "../../toolympus/components/PowerDoc";
import { apiFetchFile } from "../../toolympus/api/core";
import { generateDocx, saveDocx } from "../../toolympus/components/PowerDoc/docx";

interface GenerationData {
  stage: "template" | "params" | "generation" | "generated";
  template?: DocTemplate | null;
  params: Record<string, any>;
}

interface Config extends Partial<RecordPowerDocsConfig> {
  templatesApiPath: string;
}

export const useGenerateDocument = (cfg: Config) => {
  const [state, setState] = useState<GenerationData | null>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const templates = useLoadedData<DocTemplate[]>(`${cfg.templatesApiPath}`, [], !!state);

  const getDotxTemplate = (): Promise<Blob | null> => cfg?.dotxTemplateUrl
        ? apiFetchFile(cfg.dotxTemplateUrl)
        : Promise.resolve(null);

  const generateDocument = async () => {
    if(!state?.template) {
      return;
    }
    try {
      setIsGenerating(true);
      const template = state?.template; // await apiFetch<T>(`${apiPath}/${id}`, "get")
      const dotxTemplate = await getDotxTemplate();

      let processor = cfg?.expressionProcessor || DefaultExpressionProcessor;
      if(processor.withParams) {
        processor = processor.withParams(state.params);
      }

      const doc = processPowerDocTemplate(template, processor);
      const content = doc.content?.blocks || [];
      const images = await collectDocImages(content);
      const file = await generateDocx({ getImage: url => images[url] }, content, dotxTemplate)
      const result = await saveDocx(file, `${doc.title}.docx`);
      setState(x => x ? { ...x, stage: "generated" } : x);
      return result;
    } finally {
      setIsGenerating(false);
    }
  }

  return {
    templates,
    state,
    start: () => {
      setState({ stage: "template", params: {} });
    },
    pickTemplate: (template: DocTemplate) => {
      setState({
        template,
        stage: "params",
        params: {},
      })
    },

    updateParams: (changes: Record<string, any>) => {
      setState(x => x ? ({ ...x, params: { ...(x.params || {}), ...changes }}) : x);
    },

    goBack: () => {
      const prevStage = ({ params: "template", generation: "params" } as any)[state?.stage || ""];
      if(prevStage) {
        setState(x => x ? ({ ...x, stage: prevStage }) : x);
      }
    },

    close: () => {
      setState(null);
    },

    generateDocument,
    isGenerating,
  }
  
}


export type GenerateDocumentData = ReturnType<typeof useGenerateDocument>;
