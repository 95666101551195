import styled from '@emotion/styled';
import React, { useState, useEffect, PropsWithChildren } from 'react';


const Slots = styled.div`
  display: flex;
  gap: 10px;
  height: 120px;
  overflow: hidden;
`;

const Slot = styled.div`
  width: 80px;
  height: 120px;
  background: white;
  border: 2px solid ${props => props.theme.palette.primary.main};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
`;

const SlotsReel = styled.div`
  position: relative;
  height: 120px; /* Adjust based on your item height */
  width: 80px; /* Adjust to your needs */

  @keyframes spin {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1000%); /* Adjust based on number of items */
    }
  }
  
  animation: spin 1.2s infinite linear;

  &.speed-1 { animation: spin 1.6s infinite linear; }
  &.speed-2 { animation: spin 2.0s infinite linear; }
`;


const Machine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #5B99D240;
  border-radius: 10px;
  width: fit-content;

  &.not-spinning {
    & ${SlotsReel} {
      /* animation: none; */
      animation-play-state: paused;
    }
  }
`;


interface SlotMachineProps {
  isSpinning: boolean;
  spinRelease?: number;
  lingerDuration?: number;
}

export const SlotMachine: React.FC<PropsWithChildren<SlotMachineProps>> = (props) => {
  const { isSpinning } = props;
  
  // 10 different emoji images for the slots
  const slotImages = ['🍒', '🍋', '🍊', '🍇', '🍉', '🍓', '🍍', '🥝', '🥥', '🍎'];

  const [isRolling,setIsRolling] = useState<boolean>(false);
  const [isLingering,setIsLingering] = useState<boolean>(false);

  const [offsets,setOffsets] = useState<number[]>([0,1,2].map(() => Math.floor(Math.random()*10)))

  useEffect(() => {
    if(isSpinning) {
      setOffsets([0,1,2].map(() => Math.floor(Math.random()*10)));
      setIsRolling(true);
    } else {
      const timeout = setTimeout(() => setIsRolling(false), props.spinRelease || 500);
      return () => clearTimeout(timeout);
    }
  }, [isSpinning, props.spinRelease]);

  useEffect(() => {
    if(isRolling) {
      setIsLingering(true);
    } else {
      const timeout = setTimeout(() => setIsLingering(false), props.lingerDuration || 500);
      return () => clearTimeout(timeout);
    }
  }, [isRolling, props.lingerDuration]);

  return (
    <Machine className={`slot-machine ${isRolling ? "spinning" : "not-spinning"} ${isLingering ? "lingering" : ""}`}>
      <Slots>
        <SlotsReel className={`speed-${offsets[0]%3}`}>
          {Array.from({ length: 20 }).fill(0).map((x,i) => (
            <Slot key={i}>{slotImages[Math.floor((i+offsets[0])%10)]}</Slot>
          ))}
        </SlotsReel>
        <SlotsReel className={`speed-${offsets[1]%3}`}>
          {Array.from({ length: 20 }).fill(0).map((x,i) => (
            <Slot key={i}>{slotImages[Math.floor((i+offsets[1])%10)]}</Slot>
          ))}
        </SlotsReel>
        <SlotsReel className={`speed-${offsets[2]%3}`}>
          {Array.from({ length: 20 }).fill(0).map((x,i) => (
            <Slot key={i}>{slotImages[Math.floor((i+offsets[2])%10)]}</Slot>
          ))}
        </SlotsReel>
      </Slots>
    </Machine>
  );
};
