import React from 'react';
import styled from '@emotion/styled';
import { DocumentTemplateParamsConfigData } from './useDocumentTemplateParamsConfig';
import { Buttons, FormGrid, MoreActionsMenuButton, useCopyText } from '../../toolympus/components/primitives';
import { Button, IconButton, Typography } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import isHotkey from 'is-hotkey';

interface Props {
  data: DocumentTemplateParamsConfigData;
}

const ParamWrapper = styled.div`
  padding-right: 12px;
  position: relative;
  & .actions {
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(0.75);
    transform-origin: top right;
  }

  & .tags {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    gap: 10px;
    row-gap: 3px;

    margin-left: 18px;
  }
`;

export const DocumentTemplateParamsConfig = (props: Props) => {
  const { data } = props;

  const copyText = useCopyText();

  return (
    <FormGrid columns="1fr">
      <Typography variant="caption" color="textSecondary">Параметры</Typography>

      {data.params.map((p,i) => data.editParam.item?._id === p._id
        ? (
          <FormGrid
            columns="1fr"
            onKeyUp={e => {
              if(isHotkey("Escape", e)) {
                data.editParam.cancel();
              } else if(isHotkey("mod+enter", e)) {
                data.editParam.save();
              }
            }}>
            <FormControlsForFields
              data={data.editParam.item}
              onChange={(o,c) => data.editParam.update(c)}
              schema={data.schema}
              fields={[
                ["code", { controlProps: { autoFocus: true }}],
                ["label"],
              ]}
              />

            <Buttons style={{ justifyContent: "flex-end" }}>
              <Button
                size="small"
                color="primary"
                disabled={!data.editParam.item?.code || !data.editParam.item?.label}
                onClick={() => data.editParam.save()}>
                  применить
              </Button>
              <IconButton
                size="small"
                onClick={() => data.editParam.cancel()}>
                  <Close />
              </IconButton>
            </Buttons>
          </FormGrid>
        )
        : (
          <ParamWrapper key={p._id}>
            <Typography>{i+1}. {p.label} <Typography variant="caption" color="textSecondary">{p.fieldtype || "текст"}</Typography></Typography>
            <div className="tags">
              <Typography variant="caption" color="textSecondary">{p.code}</Typography>
            </div>

            <MoreActionsMenuButton
              className="actions"
              actions={[
                ["copycode", "скопировать код", () => copyText(`params.${p.code}`)],
                ["edit", "редактировать", () => data.editParam.startEditing({ ...p })],
                ["remove", "удалить", () => data.removeParam.run(p)],
              ]}
              />
          </ParamWrapper>
      ))}

      {!!data.newParam.isEditing &&
        <FormGrid
          columns="1fr"
          onKeyUp={e => {
            if(isHotkey("Escape", e)) {
              data.newParam.cancel();
            } else if(isHotkey("mod+enter", e)) {
              data.newParam.save();
            }
          }}>
          <FormControlsForFields
            data={data.newParam.item}
            onChange={(o,c) => data.newParam.update(c)}
            schema={data.schema}
            fields={[
              ["code", { controlProps: { autoFocus: true }}],
              ["label"],
            ]}
            />

          <Buttons style={{ justifyContent: "flex-end" }}>
            <Button
              size="small"
              color="primary"
              disabled={!data.newParam.item?.code || !data.newParam.item?.label}
              onClick={() => data.newParam.save()}>
                добавить
            </Button>
            <IconButton
              size="small"
              onClick={() => data.newParam.cancel()}>
                <Close />
            </IconButton>
          </Buttons>
        </FormGrid>}

      <Buttons style={{ justifyContent: "flex-end" }}>
        {!data.newParam.isEditing &&
          <Button color="primary" size="small" startIcon={<Add />} onClick={() => data.newParam.startEditing()}>добавить параметр</Button>}
      </Buttons>
    </FormGrid>
  );
}
