import React, { ReactNode } from 'react';
import { GenerateDocumentData } from './useGenerateDocument';
import { Dialog, LoadingIndicator } from '../../toolympus/components/primitives';
import { Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import { FormControlsForFields } from '../../toolympus/components/schemed';

interface Props {
  data: GenerateDocumentData;
  title?: ReactNode;
}

export const GenerateDocumentDialog = (props: Props) => {
  const { data, title } = props;

  return (
    <Dialog
      isOpen={!!data.state}
      close={() => data.close()}
      dialogTitle={title}
      titleActions={<>
        {(data.isGenerating || data.templates.isLoading) && <LoadingIndicator sizeVariant="s" />}
      </>}
      noFullscreen
      actions={<>
        {data.state?.stage === "params" &&
          <Button size="small" onClick={() => data.goBack()}>назад</Button>}
        {data.state?.stage === "params" &&
          <Button color="primary" size="small" onClick={() => data.generateDocument()}>сгенерировать</Button>}
        {data.state?.stage === "generated" &&
          <Button color="primary" size="small" onClick={() => data.close()}>закрыть</Button>}
      </>}>

      {data.state?.stage === "template" && <>
        <Typography variant="caption" color="textSecondary">Выберите шаблон</Typography>
        <List>
          {data.templates.data.map(template => (
            <ListItem key={template._id} button onClick={() => { data.pickTemplate(template) }}>
              <ListItemText primary={template.title} />
            </ListItem>
          ))}
        </List>
      </>}

      {data.state?.stage === "params" && !!data.state?.template?.template_parameters?.length && <>
        <Typography variant="caption" color="textSecondary">Заполните параметры</Typography>
        <FormControlsForFields
          data={data.state?.params || {}}
          onChange={(o,c) => data.updateParams(c)}
          schema={{}}
          fields={data.state.template.template_parameters.map(p => ([p.code, { label: p.label }]))}
          />
      </>}

      {data.state?.stage === "generated" && <>
        <Typography color="textSecondary">Документ сгенерирован</Typography>
      </>}

    </Dialog>
  );
}
