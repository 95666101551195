import React, { ReactNode, useCallback, useState } from 'react';
import { IconButton } from '@mui/material';
import { Message, ChatBubbleOutline } from '@mui/icons-material';
import { useLoadedData } from '../../hooks/useLoadedData';
import { Settings, Comment, useComments } from './useComments';
import { CommentsDialog } from './CommentsForm';
import { useCommentsConfiguration } from './CommentsConfiguration';

export interface TableComments {
    buttonForRecord: (recordId: string) => ReactNode;
    popup: ReactNode;
}

export interface TableCommentsExt extends TableComments {
  commentsForRecord: (recordId: string) => Comment[];
  openDialog: (recordId: string) => void;
}

type AllComments = Record<string, Comment[]>;

const Empty: Comment[] = [];

export const useTableComments = (apiPrefix: string, entity: string, settings?: Settings): TableCommentsExt => {
    const allComments = useLoadedData<AllComments>(`${apiPrefix}/${entity}`, {}, !settings?.suppressLoad);
    const [activeRecordId, setActiveRecordId] = useState<string | null>(null);
    const activeRecordComments = useComments(apiPrefix, entity, activeRecordId || '', { ...settings, suppressLoad: activeRecordId === null });

    const config = useCommentsConfiguration();
    const AbsentIcon = config.commentAbsentIcon || <ChatBubbleOutline />
    const PresentIcon = config.commentPresentIcon || <Message />

    const buttonForRecord = (recordId: string) => (
        <IconButton onClick={() => { setActiveRecordId(recordId) }} size="small" className="comments-button">
            {allComments.data[recordId] && allComments.data[recordId].length > 0 ? PresentIcon : AbsentIcon}
        </IconButton>
    );

    const popup = (<CommentsDialog
                        data={activeRecordComments}
                        isOpen={activeRecordId !== null}
                        setIsOpen={() => { setActiveRecordId(null); allComments.reload(); }} />);

    const commentsForRecord = useCallback((recordId: string) => {
      const raw = allComments.data[recordId] || Empty;
      if(settings?.getUserName) {
        const getUserName = settings.getUserName;
        return raw.map(c => ({ ...c, user_name: getUserName(c.user_id) || ""}));
      } else {
        return raw;
      }
    }, [allComments.data, settings?.getUserName]);

    return {
        buttonForRecord,
        popup,

        commentsForRecord,
        openDialog: recordId => setActiveRecordId(recordId),
    };
}
