import { generateCode } from "../../toolympus/api/data";
import { useItemActionWithConfirmation } from "../../toolympus/api/useAction";
import { useEditItem2 } from "../../toolympus/api/useNewItem";
import { Schema } from "../../toolympus/hooks/useSchema";

interface Config {
  data: any;
  update: (v: any) => void;
}

interface TemplateParam {
  _id: string;
  code: string;
  label: string;
  fieldtype?: string;
}

const Empty: TemplateParam[] = [];

export const TemplateParamSchema: Schema = {
  code: { label: "Код" },
  label: { label: "Название" },
}

export const useDocumentTemplateParamsConfig = (cfg: Config) => {
  const { data, update } = cfg;
  const params = data as TemplateParam[] || Empty;
  const newParam = useEditItem2<TemplateParam>({
    save: (item) => {
      const itemX = { ...item, _id: generateCode() };
      update([...params, itemX ]);
      return Promise.resolve(itemX);
    }
  });

  const editParam = useEditItem2<TemplateParam>({
    save: (item) => {
      update(params.map(x => x._id === item._id ? item : x));
      return Promise.resolve(item);
    }
  });

  const removeParam = useItemActionWithConfirmation<TemplateParam, {}>(
    item => {
      update(params.filter(p => p._id !== item._id));
      return Promise.resolve({});
    }, {
      title: "Удалить параметр",
    }
  );


  return {
    params,
    newParam: {
      ...newParam,
      startEditing: () => {
        newParam.startEditing({ _id: "", code: "", label: "" });
      },
    },
    editParam,
    removeParam,
    schema: TemplateParamSchema,
  }
}


export type DocumentTemplateParamsConfigData = ReturnType<typeof useDocumentTemplateParamsConfig>;
